import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Auth from '../Auth';

function Callback({ history }) {
  const [error, setError] = useState(null)
  const [countdown, setCountdown] = useState(9);

  useEffect(() => {
    if (!error) return
    let isMounted = true;
    if (countdown) {
      setTimeout(() => isMounted && setCountdown(countdown - 1), 1000)
    } else {
      history.push("/logout")
    }
    return () => isMounted = false;
  }, [countdown, history, error])

  useEffect(() => {
    Auth
      .handleAuthentication()
      .then(() => history.push('/'))
      .catch(setError)
  }, [history])

  return (
    <h4 className="m-3 text-center text-black-50">{
      error
        ? <>Failed to load profile. Logging out in . . . <span className="text-danger">{countdown}</span></>
        : "Loading profile . . ."
    }</h4>
  )
}

export default withRouter(Callback);
