import React, {
  forwardRef,
  useState,
  useRef,
  Fragment,
  useEffect,
} from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Menu,
  MenuItem,
  Button,
  Paper,
  Collapse,
  Input,
} from "@material-ui/core";
import {
  Add,
  Delete,
  DragIndicator,
  FileCopy,
  MoreVert,
  Edit,
  Share,
  ChevronRight,
  Cancel,
} from "@material-ui/icons";
import { createGroup } from "../../../../../../api/api";
import "./index.css";

function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

const EntityCard = forwardRef(
  (
    {
      dense,
      entity,
      onClick,
      style,
      onEditClick,
      onShareClick,
      onDeleteClick,
      onCopyClick,
    },
    ref
  ) => {
    const isOnScreen = useOnScreen(ref);

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showAddGroup, setShowAddGroup] = useState(false);
    const menuRef = useRef();
    const inputRef = useRef();
    const containerRef = useRef();

    const handleShowAddGroup = () => {
      if (entity.handle) {
        setShowAddGroup(!showAddGroup);
        setIsMenuOpen(false);
        inputRef.current.focus();
      } else {
        window.alert("Please add a handle before creating a subgroup.");
      }
    };

    const title =
      /*entity.keyValues?.get('name') ?? entity.keyValues?.get('title') ??*/ entity.handle ??
      entity.id ??
      "Create New";
    return (
      <Grid
        ref={ref}
        item
        md={dense ? 2 : 3}
        sm={dense ? 3 : 6}
        xs={dense ? 6 : 12}
        className={`entity-card${dense ? " dense" : ""}`}
        style={style}
      >
        <div className="pre-card">
          <Card className="card">
            <CardHeader
              className="header"
              title={title}
              avatar={
                <IconButton
                  children={Boolean(entity.id) && <DragIndicator />}
                  className="more float-right"
                  size={dense ? "small" : "medium"}
                />
              }
              action={
                <IconButton
                  ref={menuRef}
                  size={dense ? "small" : "medium"}
                  onClick={() => setIsMenuOpen(true)}
                  children={title === "Create New" ? <Add /> : <MoreVert />}
                  color="primary"
                />
              }
            />
            <Link>
              <CardMedia
                ref={containerRef}
                className={`image${entity.image?.src ? "" : " missing"}`}
                style={{
                  height: 135,
                  padding: 0,
                }}
                image={isOnScreen ? entity.image?.src : null}
              >
                <div
                  style={{
                    fontSize: 12,
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    placeItems: "start",
                    placeContent: "start",
                    marginBottom: "auto",
                    lineHeight: 1.1,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                    gap: "0 4px",
                  }}
                >
                  {Array.from(entity.keyValues.entries())
                    .slice(0, 8)
                    .map(([key, value], i) => (
                      <Fragment key={i}>
                        <b>{key}</b> {value}
                      </Fragment>
                    ))}
                </div>
              </CardMedia>
            </Link>
            <div
              className={`${dense ? "card-footer-dense" : "card-footer"} ${
                !Boolean(entity.groups) ? "groupless" : ""
              }`}
            >
              <Button onClick={onEditClick} className="footer-button">
                {" "}
                <Edit
                  color="primary"
                  fontSize={dense ? "small" : "large"}
                />{" "}
              </Button>
              {entity?.groups?.length > 0 && (
                <Button
                  onClick={() => onClick(entity)}
                  className="footer-button"
                >
                  {" "}
                  <ChevronRight
                    color="primary"
                    fontSize={dense ? "small" : "large"}
                  />{" "}
                </Button>
              )}
            </div>
          </Card>
          <Menu
            anchorEl={menuRef.current}
            open={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          >
            {!Boolean(entity.groups) && (
              <Tooltip title="Add Subgroup" arrow placement="right-end">
                <MenuItem onClick={handleShowAddGroup}>
                  <Add color="primary" />
                </MenuItem>
              </Tooltip>
            )}
            <Tooltip title="Share" arrow placement="right-end">
              <MenuItem
                onClick={() => {
                  onShareClick(entity);
                  setIsMenuOpen(false);
                }}
              >
                <Share color="primary" />
              </MenuItem>
            </Tooltip>
            <Tooltip title="Copy Template" arrow placement="right-end">
              <MenuItem onClick={() => onCopyClick(entity)}>
                <FileCopy />
              </MenuItem>
            </Tooltip>
            <Tooltip title="Delete" arrow placement="right-end">
              <MenuItem onClick={onDeleteClick}>
                <Delete color="secondary" />
              </MenuItem>
            </Tooltip>
          </Menu>
        </div>
        <Collapse in={showAddGroup}>
          <Paper>
            <Input
              startAdornment={
                <IconButton onClick={() => setShowAddGroup(false)}>
                  <Cancel color="secondary" />
                </IconButton>
              }
              fullWidth
              placeholder="New Group"
              inputProps={{ id: "test" }}
              inputRef={inputRef}
              onKeyUp={async (e) => {
                if (e.key !== "Enter") return;
                if (inputRef.current.value.toLowerCase() === "websites") {
                  window.alert(
                    "The handle 'websites' is reserved, please pick a new name"
                  );
                  return;
                }
                await createGroup(
                  entity.id,
                  inputRef.current.value.toLowerCase().replace(" ", "-"),
                  1
                );
                onClick(entity);
              }}
            />
          </Paper>
        </Collapse>
      </Grid>
    );
  }
);

export default EntityCard;
