import { FormControl, TextField, InputLabel, List, ListItem, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import KeyValueField from './KeyValueField';

const KeyValueFields = ({ value, onChange }) => {
    const [keyValues, setKeyValues] = useState(Array.from(value));
    const [shrink, setShrink] = useState(keyValues.length > 0);

    const [newKey, setNewKey] = useState('');
    const [newKeyError, setNewKeyError] = useState('');

    useEffect(() => {
        const hasChanges = keyValues.length !== value.size || keyValues.some(([k, v]) => value.get(k) !== v);
        if (!hasChanges) return;

        onChange(new Map(keyValues.filter(([k]) => k)));
    }, [value, onChange, keyValues]);

    useEffect(() => {
        document.getElementById('newKeyField').scrollIntoView()
        setNewKeyError('');
    }, [newKey])

    const handleFocus = () => !shrink && setShrink(true);
    const handleBlur = () => {
        setNewKey('');
        if (keyValues.length > 0) return;
        setShrink(false);
    }

    const addNewKey = () => {
        const nk = newKey.trim();
        if (!nk) return setNewKeyError(`New field cannot be empty.`);
        const existingField = keyValues.find(([key]) => key.toUpperCase() === nk.toUpperCase())?.[0];
        if (existingField) return setNewKeyError(`${existingField} is already a field.`);

        keyValues.push([newKey, '']);
        setNewKey('');
        setKeyValues([...keyValues])
    }

    return (
        <FormControl
            className="key-value-fields"
            margin="dense"
            fullWidth
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            <InputLabel shrink>Custom Fields</InputLabel>
            <List className="pb-0 pt-3">
                {
                    keyValues.map((kv, i) => (
                        <ListItem key={i} dense className="pb-0">
                            <KeyValueField
                                keyValue={[...kv]}
                                onChange={(k, v, invalidate) => {
                                    const existingKey = keyValues.find((kv, idx) => kv[0].toUpperCase() === k.toUpperCase() && idx !== i)?.[0]
                                    if (existingKey) return invalidate({ key: `${existingKey} is already a field.` });
                                    keyValues.splice(i, 1, k ? [k, v] : null);
                                    setKeyValues(keyValues.filter(e => e));
                                }}
                            />
                        </ListItem>
                    ))
                }
                <ListItem className="py-0">
                    <Grid item xs={12}>

                        <TextField
                            style={{ marginBottom: -20, paddingRight: 5, marginLeft: 2 }}
                            error={Boolean(newKeyError)}
                            inputProps={{
                                style: {
                                    padding: 0,
                                    margin: "4.8px",
                                    fontSize: 13
                                }
                            }}
                            fullWidth
                            id="newKeyField"
                            margin="dense"
                            value={newKey}
                            onChange={e => setNewKey(e.target.value.trim() && e.target.value.replace(/ |--/g, '-').trim().toLowerCase())}
                            placeholder="Type Here to Add New Field"
                            onBlur={handleBlur}
                            onKeyUp={e => e.key === 'Enter' && addNewKey(e)}

                        />
                        <TextField
                            fullWidth
                            disabled
                            variant="outlined"
                            margin="dense"
                            placeholder={newKeyError || (newKey && "Press Enter to Add")}
                        />
                    </Grid>
                </ListItem>
            </List>
        </FormControl>
    );
}

export default KeyValueFields;