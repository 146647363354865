import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { Breadcrumbs, Link as MuiLink, Tooltip, Chip } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import {
  getGroupById,
  createGroup,
  createEntity,
  createGroupRelation,
  createEntityRelation,
  updateGroup,
  getStateByPath,
  getHerokuStatusByHandle,
} from "../../../api/api";
import ToolContainer from "../sharedComponents/ToolContainer";
import EntityDrawer from "./common/EntityDrawer";
import GroupEntities from "./common/GroupEntities";
import EntityModal from "./common/EntityModal";
import EntityHeader from "./common/EntityHeader";
import ShareModal from "./common/ShareModal";
import "./index.css";
import HerokuDeployModal from "../../global/HerokuDeployModal";

const CMS = ({
  match: {
    params: { handles: _handles },
  },
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [entityData, setEntityData] = useState();
  const [groupData, setGroupData] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [entityBeingEdited, setEntityBeingEdited] = useState(null);
  const [groupToShare, setGroupToShare] = useState();
  const [entityToShare, setEntityToShare] = useState();
  const [dense, setDense] = useState(false);
  const [herokuStatus, setHerokuStatus] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [environment, setEnvironment] = useState();
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);

    let isMounted = true;
    async function setData() {
      const path = history.location.pathname.split("/").splice(1);
      const { entity, group } = await getStateByPath(path);
      if (!isMounted) return;

      setEntityData(entity);
      setGroupData(group);

      const doesPathEndOnGroup = path.length % 2 === 0;
      const isPathOnCurrentGroup = path[path.length - 1] !== group?.handle;
      if (doesPathEndOnGroup && isPathOnCurrentGroup) {
        history.replace({
          pathname: "/" + path.join("/") + (group ? "/" + group.handle : ""),
        });
      }
    }
    setData().then(() => setIsLoading(false));

    return () => (isMounted = false);
  }, [history]);

  useEffect(() => {
    const path = history.location.pathname.split("/").slice(1);
    setBreadcrumbs(path);
  }, [history.location.pathname]);

  const handleEntityClick = async (entityClicked) => {
    setIsLoading(true);

    let path = history.location.pathname + "/" + entityClicked.handle;
    const pathArr = path.split("/").slice(1);
    const { entity, group } = await getStateByPath(pathArr);
    setEntityData(entity);
    setGroupData(group);
    path = history.location.pathname + "/" + entity.handle + "/" + group.handle;
    history.replace({ pathname: path });

    setIsLoading(false);
  };

  const onDeployClick = async (selectedEnvironment) => {
    setEnvironment(selectedEnvironment);
    const activeWebsite =
      history.location.pathname.split("/")[2] +
      (environment === "staging" ? "-" + environment : "");
    setIsModalOpen(true);
    await getHerokuStatusByHandle(activeWebsite).then(setHerokuStatus);
  };

  const handleGroupClick = async (group) => {
    if (group?.id === groupData?.id) return;
    setIsLoading(true);

    const newGroupData = await getGroupById(group.id);
    setGroupData(newGroupData);

    let path = history.location.pathname.split("/");
    if (path[path.length - 1] !== entityData.handle) path.pop();
    path.push(group.handle);
    history.push(path.join("/"));

    setIsLoading(false);
  };

  const handleBreadcrumbsClick = async (i) => {
    setIsLoading(true);
    const path = history.location.pathname.split("/").slice(1, i + 2 + (i % 2));
    const { entity, group } = await getStateByPath(path);

    setEntityData(entity);
    setGroupData(group);

    history.push("/" + path.join("/"));

    setIsLoading(false);
  };

  const handleCreateEntity = async ({
    groupId,
    handle,
    image,
    keyValues,
    tags,
  }) => {
    if (!!groupData.entities) {
      for (let i = 0; i < groupData.entities.length; i++) {
        if (!handle || groupData.entities[i].handle !== handle) continue;
        window.alert("This handle already exists within this parent");
        return;
      }
    }
    const newEntity = await createEntity(
      groupId,
      handle,
      image,
      keyValues,
      tags
    );
    setGroupData({
      ...groupData,
      entities: [newEntity, ...(groupData.entities ?? [])],
    });
    setEntityBeingEdited(null);
  };

  const handleCreateTemplatedEntity = (entity) => {
    setEntityBeingEdited({
      id: null,
      handle: null,
      groupId: groupData.id,
      keyValues: new Map(
        Array.from(entity.keyValues ?? []).reduce(
          (acc, [key]) => [...acc, [key, null]],
          []
        )
      ),
    });
  };

  const handleUpdateEntity = async () => {
    setIsLoading(true);

    setEntityBeingEdited(null);
    const path = history.location.pathname.split("/").splice(1);
    const { entity, group } = await getStateByPath(path);

    setEntityData(entity);
    setGroupData(group);
    const doesPathEndOnGroup = path.length % 2 === 0;
    const isPathOnCurrentGroup = path[path.length - 1] !== group?.handle;
    if (doesPathEndOnGroup && isPathOnCurrentGroup) {
      history.replace("/" + path.join("/") + (group ? "/" + group.handle : ""));
    }

    setIsLoading(false);
  };

  const handleGroupDelete = async (deletedGroup) => {
    setIsLoading(true);
    if (groupData.id === deletedGroup.id) {
      let path = history.location.pathname.split("/").splice(1);
      path.pop();
      path.pop();

      const { entity, group } = await getStateByPath(path);
      setGroupData(group);
      if (group.handle !== "websites") {
        setEntityData(entity);
        path = path.join("/");
        history.replace("/" + path);
      } else {
        setEntityData(null);
        history.replace("/websites");
      }
      setIsLoading(false);
      return;
    }

    setEntityData({
      ...entityData,
      groups: entityData.groups.filter((e) => e.id !== deletedGroup.id),
    });
    setIsLoading(false);
  };

  const handleChipClick = async (path) => {
    setIsLoading(true);
    if (path !== history.location.pathname) {
      let pathArr = path.split("/").splice(1);
      const { entity, group } = await getStateByPath(pathArr);
      setEntityData(entity);
      setGroupData(group);
      history.replace(path);
    }
    setEntityBeingEdited(null);
    setIsLoading(false);
  };

  return (
    <ToolContainer
      className={`${
        history.location.pathname === "/websites" ? "cms cms-root" : "cms"
      }`}
      title={entityData?.handle ?? ""}
      containment="none"
      isLoading={isLoading}
      breadcrumbs={
        breadcrumbs.length > 1 && (
          <Breadcrumbs
            className="breadcrumbs"
            separator={<ChevronRight fontSize="small" />}
          >
            {breadcrumbs.map((crumb, i) => (
              <MuiLink key={i} onClick={() => handleBreadcrumbsClick(i)}>
                {crumb}
              </MuiLink>
            ))}
          </Breadcrumbs>
        )
      }
    >
      <EntityHeader
        onDeployClick={onDeployClick}
        dense={dense}
        onCreateNewClick={() =>
          setEntityBeingEdited({
            id: null,
            handle: null,
            groupId: groupData.id,
          })
        }
        onChangeDensity={() => setDense(!dense)}
      />
      {Boolean(entityData) && (
        <>
          <EntityDrawer
            entity={entityData}
            groups={entityData.groups}
            onGroupClick={handleGroupClick}
            onCreateNewGroup={async (id, handle) => {
              if (handle === "websites") {
                window.alert(
                  `The handle "websites" is reserved, please pick a new name`
                );
                return;
              }
              for (let i = 0; i < entityData.groups.length; i++) {
                if (entityData.groups[i].handle === handle) {
                  window.alert(
                    `The handle "${handle}" already exists within this parent`
                  );
                  return;
                }
              }
              setEntityData({
                ...entityData,
                groups: [
                  ...entityData.groups,
                  await createGroup(id, handle, entityData.groups.length + 1),
                ],
              });
            }}
            onShareClick={setGroupToShare}
            onUpdateGroup={(group) => {
              for (let i = 0; i < entityData.groups.length; i++) {
                if (entityData.groups[i].handle === group.handle) {
                  window.alert("This handle already exists within the parent");
                  throw new Error(
                    "This handle already exists within the parent"
                  );
                }
              }
              setGroupData({ ...groupData, handle: group.handle });
              return updateGroup(group);
            }}
            onDelete={handleGroupDelete}
            handleSetGroupData={(groups) =>
              setEntityData({ ...entityData, groups: groups })
            }
          />
        </>
      )}
      {Boolean(groupData) && (
        <div className="entity-container">
          <GroupEntities
            group={groupData}
            onCardClick={handleEntityClick}
            onEditEntity={setEntityBeingEdited}
            onShare={setEntityToShare}
            onDelete={(id) =>
              setGroupData({
                ...groupData,
                entities: groupData.entities.filter((e) => e.id !== id),
              })
            }
            onCopy={handleCreateTemplatedEntity}
            dense={dense}
          />
          {breadcrumbs.length > 1 && groupData.parentPaths?.length > 1 && (
            <div className="sh-footer">
              <hr className="footer-seperator" />
              <div className="inner">
                Shared With:
                {groupData.parentPaths?.map((e, i) => {
                  const site = e.split("/").slice(-2).join("/");
                  const isCurrentChip =
                    e ===
                    history.location.pathname.split("/").slice(0, -1).join("/");
                  return (
                    <Tooltip key={i} title={e} arrow>
                      <Chip
                        variant="outlined"
                        onClick={() => !isCurrentChip && handleChipClick(e)}
                        label={site}
                        className={isCurrentChip ? "current-chip" : ""}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
      <EntityModal
        title={entityBeingEdited?.handle}
        entity={entityBeingEdited}
        onClose={() => setEntityBeingEdited(null)}
        onSubmit={(e) => handleUpdateEntity(e)}
        onEntityChange={handleCreateEntity}
        onLinkClick={handleChipClick}
      />
      <ShareModal
        group={groupToShare}
        entity={entityToShare}
        onShare={async (handle, item, isEntity) => {
          if (isEntity) {
            await createEntityRelation(handle, item);
          } else {
            await createGroupRelation(handle, item);
            setGroupData({
              ...groupData,
              sharedHandles: [...groupData.sharedHandles, handle],
            });
          }
        }}
        onClose={() => {
          setGroupToShare(null);
          setEntityToShare(null);
        }}
      />
      {isModalOpen && (
        <HerokuDeployModal
          statuses={herokuStatus}
          onClose={() => setIsModalOpen(false)}
          selectedEnvironment={environment}
        />
      )}
    </ToolContainer>
  );
};

export default withRouter(CMS);
