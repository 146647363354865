import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { DragSource, DropTarget, } from 'react-dnd'

const Draggable = forwardRef(({ Component, componentProps, isDragging, connectDragSource, connectDropTarget }, ref) => {
    const elementRef = useRef(null)
    connectDragSource(elementRef)
    connectDropTarget(elementRef)
    useImperativeHandle(ref, () => ({ getNode: () => elementRef.current }))
    return (
        <Component ref={elementRef} style={{ opacity: isDragging ? 0 : 1 }} {...componentProps} />
    )
})

const drop = DropTarget(
    'draggable',
    {
        hover: ({ index, onMove }, monitor, component) => {
            if (!component) return null
            // node = HTML Div element from imperative API
            const node = component.getNode()
            if (!node) return null
            const dragIndex = monitor.getItem().index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) return
            // Determine rectangle on screen
            const hoverBoundingRect = node.getBoundingClientRect()
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Get horizontal middle

            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
            // Get pixels to the top
            const hoverClientX = clientOffset.x - hoverBoundingRect.left

            // Dragging left
            if (dragIndex < hoverIndex && (hoverClientX < hoverMiddleX && hoverClientY < hoverMiddleY)) return
            // Dragging right
            if (dragIndex > hoverIndex && (hoverClientX > hoverMiddleX && hoverClientY > hoverMiddleY)) return
            // Dragging downwards
            // if (dragIndex < hoverIndex && ) return
            // // Dragging upwards
            // if (dragIndex > hoverIndex && ) return

            // Time to actually perform the action
            onMove(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            monitor.getItem().index = hoverIndex
        },
        drop: ({ onDrop }) => onDrop()
    },
    connect => ({ connectDropTarget: connect.dropTarget() })
)

const drag = DragSource(
    'draggable',
    { beginDrag: ({ id, index }) => ({ id, index }) },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
    }))

export default drop(drag(Draggable))
