import Axios from "axios";

export async function getStateByPath(pathArr, offset, limit) {
  const path = "/" + pathArr.join("/");
  const res = await Axios.get(
    `/entities?path=${path}&offset=${offset}&limit=${limit}`
  );
  if (res.entity.id === 0) res.entity = null;
  if (res.entity) res.entity.keyValues = new Map(res.entity.keyValues);
  res.group.entities?.forEach((e) => (e.keyValues = new Map(e.keyValues)));
  return res;
}

export async function getSharedHandles(id) {
  const res = await Axios.get(`/entities/${id}`);
  return res.parentPaths;
}

export async function getGroupById(id, offset, limit) {
  if (!id) throw new Error("Expected a groupId, but recieved none");
  const res = await Axios.get(`/groups/${id}?offset=${offset}&limit=${limit}`);
  res.group.sharedHandles = res.sharedHandles;
  res.group.entities?.forEach((e) => (e.keyValues = new Map(e.keyValues)));
  return res.group;
}

export async function createEntity(groupId, handle, image, keyValues, tags) {
  const res = await Axios.post(`/entities`, {
    groupId,
    position: 1,
    entity: {
      handle,
      tags,
      keyValues: keyValues?.size ? Array.from(keyValues) : null,
      image,
    },
  });
  res.entity.keyValues = new Map(res.entity.keyValues);
  return res.entity;
}

export async function createGroup(entityId, handle, position) {
  const res = await Axios.post(`/groups`, {
    handle,
    entityId,
    position,
  });
  return res.group;
}

export async function createEntityRelation(path, entity) {
  if (path.split("/").splice(1).length % 2 === 0)
    throw new Error(
      "Invalid path length, expected an odd number of path variables"
    );
  return await Axios.post(`/entity-groups`, {
    id: entity.id,
    path,
    parent: "group",
  });
}

export async function createGroupRelation(path, group) {
  if (path.split("/").splice(1).length % 2 !== 0)
    throw new Error(
      "Invalid path length, expected an even number of path variables"
    );
  await Axios.post(`/entity-groups`, {
    id: group.id,
    path,
    parent: "entity",
  });
}

export async function updateEntity(entity) {
  entity.keyValues = entity.keyValues?.size
    ? Array.from(entity.keyValues)
    : null;
  await Axios.put(`/entities`, { entity });
}

export async function updateGroup(group) {
  await Axios.put(`/groups`, { group: group });
}

export async function updatePositions(
  entityId,
  groupId,
  parent,
  oldPosition,
  newPosition
) {
  await Axios.put(`/entity-groups`, {
    entityId,
    groupId,
    parent,
    oldPosition,
    newPosition,
  });
}

export async function deleteRelation(entityId, groupId) {
  await Axios.delete(`/entity-groups/${entityId}/${groupId}`);
}

export async function getHerokuStatusByHandle(handle) {
  return await Axios.get(`/heroku-status/${handle}`);
}

export async function herokuDeploySiteById(herokuId) {
  await Axios.post(`/heroku-deploy/${herokuId}`);
}
