import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Auth from '.';

const PrivateRoute = ({ component, path, exact }) => {
    const isAuthenticated = Auth.isAuthenticated()

    useEffect(() => {
        if (!isAuthenticated) Auth.signIn()
    }, [isAuthenticated])

    return (
        isAuthenticated
            ? <Route exact={exact} path={path} component={component} />
            : <h4 className="m-3 text-center text-black-50">Redirecting . . .</h4>
    )
}

export default PrivateRoute;
