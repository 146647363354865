import auth0 from 'auth0-js'
import axios from 'axios'

export default class AuthClient {
  static profile = null
  static auth0 = new auth0.WebAuth({
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    responseType: 'token id_token',
    redirectUri: `${process.env.REACT_APP_LOCAL}/callback`,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    scope: 'openid profile'
  })

  static hasPermission = (permissionId) => this.profile?.permissionIds?.includes(permissionId) ?? false
  static isAuthenticated = () => new Date() < new Date(this.profile?.exp * 1000)
  static signIn = () => this.auth0.authorize({ connection: 'WilsonAzure' })
  static signOut = () => this.profile = this.auth0.logout({ returnTo: `${process.env.REACT_APP_LOCAL}/logout`, clientID: process.env.REACT_APP_AUTH0_CLIENT_ID, federated: true })
  static silentAuth = () => this.callAuth0Method("checkSession", {})
  static handleAuthentication = () => this.callAuth0Method("parseHash")
  static callAuth0Method = (method, params) => new Promise((resolve, reject) => this.auth0[method](...[params,
    (err, authResult) => {
      if (err || !authResult || !authResult.idToken) return reject(err || "Empty authentication result")

      axios.defaults.headers.common['Authorization'] = `${authResult.tokenType} ${authResult.accessToken}`
      resolve(axios.get('/profile').then(res =>
        this.profile = {
          ...authResult.idTokenPayload,
          ...res.profile
        }
      ))
    }
  ].filter(Boolean)))
}
