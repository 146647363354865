import React, { useCallback, useState } from 'react';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export const ToastContext = React.createContext();

export const ToastProvider = ({ children }) => {
    const [queue, setQueue] = useState([])
    const [, setTimeoutMs] = useState(null)

    const popQueueTimeout = useCallback(q => setTimeoutMs(t => {
        if (t) clearTimeout(t);
        return q.length ? setTimeout(q => {
            popQueueTimeout(q)
            setQueue(q)
        }, q[0] ? 4000 : 500, q.slice(1)) : null
    }), [])

    const show = useCallback(error => {
        console.error(`Toast Error: ${error?.message ?? error}`)
        setQueue(q => {
            const newQ = [...q, { message: error?.message ?? error }, null];
            popQueueTimeout(newQ)
            return newQ
        })
    }, [popQueueTimeout])

    return (
        <ToastContext.Provider value={{ show }}><>
            {children}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={Boolean(queue[0])}
                transitionDuration={{ enter: 500, exit: 500 }}
            >
                <SnackbarContent
                    style={{ backgroundColor: '#d32f2f' }}
                    message={queue[0]?.message}
                    action={[
                        <IconButton key="snackbar-key" color="inherit" onClick={() => setQueue(q => {
                            const newQ = q.slice(1)
                            popQueueTimeout(newQ)
                            return newQ
                        })}>
                            <Close />
                        </IconButton>
                    ]}
                />
            </Snackbar></>
        </ToastContext.Provider>
    );
}

export const withToast = ChildComponent => props => (
    <ToastContext.Consumer>{context => <ChildComponent {...props} toast={context} />}</ToastContext.Consumer>
);