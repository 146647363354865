import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import React, { createRef, forwardRef } from 'react'
import EntityForm from './EntityForm'

const TransitionDown = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />)

const EntityModal = ({ title, onClose, entity, onEntityChange, onSubmit, onLinkClick }) => {
    const formRef = createRef()

    return (
        <Dialog
            open={Boolean(entity)}
            onClose={onClose}
            TransitionComponent={TransitionDown}
            closeAfterTransition
            disableBackdropClick
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <EntityForm
                    ref={formRef}
                    entity={entity}
                    onEntityChange={onEntityChange}
                    onSubmit={onSubmit}
                    onLinkClick={onLinkClick}
                />
            </DialogContent>
            <DialogActions className="justify-content-between px-4 pb-3">
                <Button onClick={onClose} variant="contained">Cancel</Button>
                <Button onClick={() => formRef.current.dispatchEvent(new Event('submit'))} color="primary" variant="contained">Submit</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EntityModal
