import { Button, Dialog, DialogActions, DialogContent, Slide } from '@material-ui/core'
import React, { forwardRef, useRef, useState } from 'react'
import HoverableTextField from '../HoverableTextField'
import "./index.css"

const TransitionDown = forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />)

const ShareModal = ({group, entity, onShare, onClose}) => {
    const [handle, setHandle] = useState('')
    const [inputIsInvalid, setInputIsInvalid] = useState(false)
    const invalidRef = useRef()

    const handleShare = async () => {
        try {
            await onShare(handle, group ?? entity, !!entity)
            setInputIsInvalid(false);
            onClose();
            setHandle('')
        } catch (err){
            setInputIsInvalid(true);
            window.alert("Please provide a valid handle. Remember entities can only be shared with groups, and groups with entities")
        }
    }

    const onChangeCb = (cb, ...m) => e => {
        e.target.setCustomValidity('')
        cb(m.reduce((acc, c) => acc[c]?.(), e.target.value))
    }

    return (
        <Dialog
            open={!!group || !!entity}
            onClose={onClose}
            TransitionComponent={TransitionDown}
        >
            <DialogContent>
                <HoverableTextField 
                    className="share-modal"
                    ref={invalidRef}
                    required
                    error={inputIsInvalid}
                    label="Enter Handle"
                    value={handle}
                    variant="outlined"
                    onChange={onChangeCb(setHandle, 'trim', 'toLowerCase')}
                    type="text"
                    margin="dense"
                    placeholder="/websites/my-website/products"
                />
            </DialogContent>
            <DialogActions className="justify-content-between px-4 pb-3">
                <Button onClick={onClose} variant="contained">Cancel</Button>
                <Button 
                    onClick={handleShare}
                    color="primary" 
                    variant="contained"
                >
                    Share
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ShareModal; 
