import React, { useEffect, useState } from 'react'
import { Container, Grid } from '@material-ui/core'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Draggable from '../Draggable'
import EntityCard from './EntityCard'
import { deleteRelation, updatePositions } from '../../../../../api/api'

const GroupEntities = ({ group, onCardClick, onEditEntity, onShare, onDelete, onCopy, dense }) => {
    const [entities, setEntities] = useState(group.entities)

    useEffect(() => {
        setEntities(group.entities)
    }, [group])

    const moveEntity = (dragIndex, hoverIndex) => {
        const e = [...entities]
        e.splice(dragIndex, 1)
        e.splice(hoverIndex, 0, entities[dragIndex])
        setEntities(e)
    }

    const dropEntity = (entity) => {
        const hasDifferentPositions = group.entities.some((e, i) => entities[i]?.id !== e.id)
        if (!hasDifferentPositions) return
        const oldPos = group.entities.findIndex(e => e.id === entity.id) + 1
        const newPos = entities.findIndex(e => e.id === entity.id) + 1
        group.entities = entities

        updatePositions(entity.id, group.id, 'group', oldPos, newPos)
    }

    return (
        <div className="group-entities">
            <Container fixed>
                <DndProvider backend={HTML5Backend}>
                    <Grid container alignItems="center" justify="flex-start" spacing={dense ? 1 : 3}>
                        {entities?.map((entity, i) => (
                            <Draggable
                                key={entity?.id}
                                id={entity?.id}
                                index={i}
                                onMove={moveEntity}
                                onDrop={() => dropEntity(entity)}
                                Component={EntityCard}
                                componentProps={{
                                    dense,
                                    entity,
                                    onClick: onCardClick,
                                    onEditClick: () => onEditEntity({ ...entity, groupId: group.id }),
                                    onShareClick: onShare,
                                    onDeleteClick: async () => {
                                        if (!window.confirm(`Delete ${entity.handle ? entity.handle : entity.id}?`)) return;
                                        await deleteRelation(entity.id, group.id);
                                        onDelete(entity.id)
                                    },
                                    onCopyClick: onCopy
                                }}
                            />)
                        )}
                    </Grid>
                </DndProvider>
            </Container>
        </div>
    )
}

export default GroupEntities 