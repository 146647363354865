import { Tooltip, ListItemIcon, ListItemText, ListSubheader, IconButton, Menu, MenuItem, TextField, ListItem } from '@material-ui/core'
import { DragIndicator, Edit, MoreVert, Delete, Share, Cancel } from '@material-ui/icons'
import React, { useState, useRef, forwardRef } from 'react'
import './index.css'

const GroupCard = forwardRef(({group, onShareClick, onUpdateGroup, onDelete, onClick}, ref) => { 
    const [isOpen, setIsOpen] = useState(false)
    const [isEditable, setIsEditable] = useState(false)
    const [newHandle, setNewHandle] = useState(group.handle)
    const menuRef = useRef()

    return (
        isEditable ? 
        <ListItem ref={ref}>
            <ListItemIcon>
                <Cancel onClick={() => {setIsEditable(false); setNewHandle(group.handle)}} color="secondary" />
            </ListItemIcon>
            <TextField
                placeholder="group-handle"
                autoFocus
                required
                value={newHandle}
                onChange={e => (!e.target.value || e.target.value.trim()) && (e.target.value.slice(-2) !== '- ') && setNewHandle(e.target.value.replace(/ /g, '-').trim().toLowerCase())}
                onKeyUp={e => e.key === 'Enter' && onUpdateGroup({...group, handle:newHandle}).then(() => {
                    group.handle = newHandle
                    setIsEditable(false)
                })}
            />
        </ListItem>
        : 
        (group.handle ? 
        <ListItem ref={ref} className={`drawer-list-item ${window.location.pathname.split("/").slice(-1)[0] === group.handle ? 'test' : ''}`} onClick={() => {!isOpen && onClick(group)}}>
            <ListItemIcon>
                <DragIndicator className="draggable" />
            </ListItemIcon>
            <ListItemText title={group.handle} className="group-name" primary={group.handle.replace(/-/g, ' ')} />
            <IconButton ref={menuRef} onClick={(e) => {e.stopPropagation(); setIsOpen(!isOpen)}} ><MoreVert/></IconButton>
            <Menu
                open={isOpen}
                anchorEl={menuRef.current}
                onClose={() => setIsOpen(false)}
            >
                <Tooltip title="Edit" arrow placement='right-end'>
                    <MenuItem onClick={() => {setIsEditable(true); setIsOpen(false)}}><Edit color='primary'/></MenuItem>
                </Tooltip >
                <Tooltip title="Share" arrow placement='right-end'>
                    <MenuItem onClick={async () => {await onShareClick(group); setIsOpen(false)}}><Share/></MenuItem>
                </Tooltip>
                <Tooltip title="Delete" arrow placement='right-end'>
                    <MenuItem onClick={() => window.confirm('Are you sure you want to delete?') && onDelete(group)}><Delete color='secondary'/></MenuItem>
                </Tooltip>
            </Menu>
        </ListItem>
        : 
            <ListSubheader
                children={group.id}
            />
        )
)})

export default GroupCard;