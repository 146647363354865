import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import logo from '../assets/images/wilsonelectronics-logo.svg';
import Auth from '../Auth';
import { List, ListItem, ListItemText, Typography, Divider, IconButton, Drawer } from '@material-ui/core';
import { Menu } from '@material-ui/icons';

const Navigation = ({ history }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <AppBar className="main-nav d-flex justify-content-between flex-row" position="static">
            <Link to="/"><img className="logo" src={logo} alt="Card cap" /></Link>
            {Boolean(Auth.profile) && <>
                <IconButton color="inherit" aria-label="Menu" onClick={() => setIsDrawerOpen(true)}>
                    <Menu />
                </IconButton>
                <Drawer anchor="right" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
                    <List>
                        <ListItem className="pb-3">
                            <img alt="Profile" className="profile-image rounded-circle w-25" src={Auth.profile.picture} />
                            <h6 className="ml-3 mb-0">{Auth.profile.name}</h6>
                        </ListItem>
                        <Divider />
                        <ListItem button className="px-4 py-3" onClick={() => history.push("/tools") & setIsDrawerOpen(false)}>
                            <ListItemText>
                                <Typography variant="h6" className="m-0">Tools</Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider />
                        <div className="position-fixed fixed-bottom w-100" style={{ left: 'unset', right: 'unset' }}>
                            <Divider />
                            <ListItem button className="px-4 py-3" onClick={Auth.signOut}>
                                <ListItemText>
                                    <Typography variant="h6" className="m-0">Logout</Typography>
                                </ListItemText>
                            </ListItem>
                        </div>
                    </List>
                </Drawer>
            </>}
        </AppBar>
    )
}
export default withRouter(Navigation);