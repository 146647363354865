import React, { useEffect, useState } from 'react';
import Auth from '../Auth';
import { withRouter } from 'react-router-dom';

const Logout = ({ history }) => {
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  useEffect(() => {
    Auth.silentAuth().finally(() => {
      const isLoggedOut = !Auth.isAuthenticated();
      setIsLoggedOut(isLoggedOut);
      !isLoggedOut && history.push('/')
    });
  }, [history])

  return (
    <div className="logout">
      {
        isLoggedOut
          ?
          <div>
            <img src="https://cdn2.hubspot.net/hubfs/1886759/_web/_weboost/Logos/wilsonelectronics-black.svg" alt="wilson electronics logo" />
            <div className="logout-text">Logged Out</div>
            <div className="logout-btn">
              <button variant="contained" onClick={Auth.signIn}>Login</button>
            </div>
          </div>
          : <h4 className="m-3 text-center text-white">Logging out . . .</h4>
      }
    </div>
  )
}
export default withRouter(Logout);
