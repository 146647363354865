import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Auth from "../../Auth";
import "./index.css";

const Tools = ({ history }) => {
  useEffect(() => {
    if (Auth.profile.tools.length === 0) {
      history.push("/callback");
    } else if (Auth.profile.tools.length === 1) {
      history.push(`/${Auth.profile.tools[0].handle}`);
    }
  }, [history]);

  return (
    <Grid container spacing={4} className="tools wrapper">
      {Auth.profile.tools.map((tool) => (
        <Grid key={tool.id} item xs={12} sm={6} md={4} lg={3}>
          <Button
            onClick={
              tool.handle === "unplanned-absence"
                ? () =>
                    (window.location.href =
                      "https://absence.wilsonelectronics.com")
                : () => history.push(tool.handle)
            }
            variant="contained"
            fullWidth
            className="d-block p-4"
          >
            <img
              src={tool.imageUrl}
              alt={tool.name}
              height={160}
              className={tool.id === 1 ? "ml-5" : ""}
            />
            <Typography variant="h6" gutterBottom className="mt-2 mb-0">
              {tool.name}
            </Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default withRouter(Tools);
