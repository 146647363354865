import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ToolContainer from './Tools/sharedComponents/ToolContainer';
import Auth from '../Auth';

const Page404 = ({ location, history }) => {
  const [countdown, setCountdown] = useState(9);

  useEffect(() => {
    let isMounted = true;
    if (countdown) {
      setTimeout(() => isMounted && setCountdown(countdown - 1), 1000)
    } else {
      history.push("/tools")
    }
    return () => isMounted = false;
  }, [countdown, history])

  return (
    <ToolContainer style={{ '&h1': { color: 'red' } }} title={<i className="text-danger">404 Error</i>} className="text-center">
      <h2>Page not found for <br /><code>{location.pathname}</code></h2><br /><br /><br />
      <h4>Redirecting to {Auth.profile?.tools.length === 1 ? Auth.profile.tools[0].name : 'Tools'} page in <span className="text-danger">... {countdown}</span></h4>
    </ToolContainer>
  )
}

export default withRouter(Page404);