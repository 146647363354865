import React, { useState, useRef } from 'react'
import { AppBar, Button, Grid, Toolbar, Switch, MenuItem, ButtonGroup, Popper, Grow, Paper, ClickAwayListener, MenuList } from '@material-ui/core'
import { Add, Apps, ViewComfy, ExpandMore } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

const EntityHeader = ({ onDeployClick, onCreateNewClick, onChangeDensity, dense }) => {
    const history = useHistory()
    const environmentOptions = ['Production', 'Staging'];
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const deployButtonActive = !history.location.pathname.split("/").slice(-1).includes("websites")
    const stagingDeployButtonActive = !history.location.pathname.includes("canada")

    const handleMenuItemClick = (environment) => {
        onDeployClick(environment)
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <AppBar className="group-action-bar app-bar" position="sticky" color="default">
            <Toolbar>
                <Grid spacing={2} container alignItems="center" justify="flex-start">
                    <Grid item> <Button variant="contained" onClick={onCreateNewClick}>Create New <Add /> </Button></Grid>
                    <Grid item><Apps /><Switch color="default" checked={dense} onClick={onChangeDensity} /><ViewComfy /></Grid>
                </Grid>
                {deployButtonActive &&
                    <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                        <Button
                            size="medium"
                            onClick={handleToggle}
                        >PUBLISH <ExpandMore />
                        </Button>
                    </ButtonGroup>
                }
                <Popper
                    sx={{
                        zIndex: 1,
                    }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                        {environmentOptions.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                disabled={!stagingDeployButtonActive && index === 1}
                                                onClick={() => handleMenuItemClick(environmentOptions[index].toLowerCase())}
                                            >
                                                {option.toUpperCase()}
                                            </MenuItem>))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Toolbar>
        </AppBar>
    )
}

export default EntityHeader