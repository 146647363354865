// WithAxios.js - https://stackoverflow.com/questions/64296505/usecontext-inside-axios-interceptor

import { useContext, useMemo } from 'react'
import { ToastContext } from './ToastProvider'
import axios from 'axios'

const WithAxios = ({ children }) => {
    const { show } = useContext(ToastContext)

    useMemo(() => {
        axios.interceptors.response.use(res => res.data, e => {
            show(e.response ? `Error ${e.response.status}: ${e.response.data}` : e)
            return Promise.reject(e)
        })
    }, [show])

    return children
}

export default WithAxios