import React, { useEffect, useState } from 'react';
import { matchPath, Route, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from './Auth/PrivateRoute';
import Navigation from './components/Navigation';
import Page404 from './components/Page404';
import Auth from './Auth';
import staticRoutes from './static/routes';
import './App.css';


const App = ({ location: { pathname } }) => {
   const isAuthenticated = Auth.isAuthenticated();
   const isPathPublic = staticRoutes.find(r => matchPath(pathname, r))?.isPublic ?? false;
   const [isCheckingAuth, setIsCheckingAuth] = useState(!isPathPublic && !isAuthenticated);

   useEffect(() => {
      if (!isCheckingAuth) return;
      let isMounted = true;
      console.log("silent authing")
      Auth.silentAuth().finally(() => isMounted && setIsCheckingAuth(false));
      return () => isMounted = false;
   }, [isCheckingAuth]);

   return (
      <div className='App'>
         {isAuthenticated && <Navigation />}
         {isCheckingAuth ? <h4 className='m-3 text-center text-black-50'>Authenticating . . .</h4> :
            <Switch>

               {staticRoutes.map((r, i) => r.isPublic
                  ? <Route key={i} exact {...r} />
                  : <PrivateRoute key={i} exact {...r} />)}
               <Route component={Page404} />
            </Switch>}
      </div>
   );
}

export default withRouter(App);
