import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'
import LoadingBackdrop from '../CMS/common/LoadingBackdrop'
import './ToolContainer.css'

const ToolContainer = ({ className, title, history, children, style, containment, backTo, breadcrumbs, isLoading }) => {
    return (
        <div className={`tool-container containment-${containment}`} style={{ ...style || {} }}>
            {isLoading !== undefined && <LoadingBackdrop open={isLoading} />}
            <div className="tool-header">
                <h1>{title}</h1>
                <Button variant="contained" onClick={() => backTo?.onClick ? backTo.onClick() : history.push(backTo?.path ?? "/tools")}>
                    <ChevronLeft /> <b>Back to {backTo?.name ?? "Tools"}</b>
                </Button>
            </div>
            {breadcrumbs}
            <hr />
            <div className="tool-contents">
                <div className={className}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default withRouter(ToolContainer)