import React, { useEffect, useState } from 'react'
import { Grid, TextField } from '@material-ui/core'
import './index.css'

const KeyValueField = ({ keyValue: [_key, _value], onChange }) => {
    const [key, setKey] = useState('')
    const [value, setValue] = useState('')
    const [keyError, setKeyError] = useState(null)
    const [valueError, setValueError] = useState(null)
    const [isValueFocused, setIsValueFocused] = useState(false);


    useEffect(() => {
        setKey(_key)
        setValue(_value)
        return () => {
            setKey('')
            setValue('')
        }
    }, [_key, _value])

    const handleInvalidate = ({ key, value }, target) => {
        if (!key && !value) return
        setKeyError(key)
        setValueError(value)
        target.focus()
    }
    const focusNext = inputEl => {
        const inputs = Array.from(document.querySelectorAll('input'))
        inputs[inputs.indexOf(inputEl) + 1].focus()
    }

    const handleBlur = e => {
        if (key !== _key && value !== _value) return
        onChange(key.trim(), value?.trim(), validations => handleInvalidate(validations, e.target))

        setIsValueFocused(false)
    }

    return (
        <Grid className="key-value-field" container>
            <Grid item xs={12}>
                <TextField
                    style={{ marginBottom: -20, paddingRight: 5, marginLeft: 2 }}
                    error={Boolean(keyError)}
                    helperText={keyError}
                    inputProps={{
                        style: {
                            padding: 0,
                            margin: "4.8px",
                            fontSize: 13
                        }
                    }}
                    fullWidth
                    margin="dense"
                    value={key}
                    onChange={e => {
                        setKey(e.target.value.trim() && e.target.value.replace(/ |--/g, '-').trim().toLowerCase())
                        setKeyError(null)
                    }}
                    onBlur={handleBlur}
                    onKeyUp={e => e.key === 'Enter' && focusNext(e.target)}

                />
                <TextField
                    multiline
                    autoFocus
                    error={Boolean(valueError)}
                    helperText={valueError}
                    fullWidth
                    rowsMax={isValueFocused ? "" : "2"}
                    inputProps={{
                        style: {
                            overflow: isValueFocused ? "auto" : "hidden",
                            cursor: "auto"
                        }
                    }}
                    onClick={() => {
                        setIsValueFocused(true);
                    }}
                    name={`keyValue.${key}`}
                    variant="outlined"
                    margin="dense"
                    value={value ?? ''}
                    required
                    onChange={e => {
                        setValue(e.target.value)
                        setValueError(null)
                    }}
                    onBlur={handleBlur}
                />
            </Grid>
        </Grid >
    )
}

export default KeyValueField
