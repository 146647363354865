import React, { forwardRef, useState } from 'react'
import KeyValueFields from '../KeyValueFields'
import ImageFields from '../ImageFields'
import HoverableTextField from '../HoverableTextField'
import { updateEntity, getSharedHandles } from '../../../../../api/api'
import { Tooltip, Chip } from "@material-ui/core"
import { useEffect } from 'react'

const EntityForm = forwardRef(({
    entity,
    onEntityChange,
    onSubmit,
    onLinkClick
}, ref) => {
    const [handle, setHandle] = useState(entity?.handle ?? '')
    const [tags, setTags] = useState(entity?.tags ?? '')
    const [keyValues, setKeyValues] = useState(entity?.keyValues ?? [])
    const [image, setImage] = useState(entity?.image ?? { alt: '', src: '' })
    const [sharedHandles, setSharedHandles] = useState([])

    useEffect(() => {
        if (entity?.id === undefined) return
        let isMounted = true
        getSharedHandles(entity.id).then(sh => isMounted && setSharedHandles(sh))
        return () => isMounted = false;
    }, [entity?.id])

    const getDataItemFromForm = form => {
        const formDataItem = Object.fromEntries(Array.from(new FormData(form)).map(([k, v]) => [k, v || null]))
        const { handle, tags, imageAlt: alt, imageSrc: src, ...formKeyValues } = formDataItem
        const image = Boolean(alt && src) ? { alt, src } : null
        const keyValues = Object.keys(formKeyValues).length ? new Map(Object.entries(formKeyValues).map(([k, v]) => [k.replace('keyValue.', ''), v])) : null
        return { handle, tags, image, keyValues }
    }

    const getUpdatedValue = (key, oldVal, newVal) => {
        if (oldVal === newVal) return undefined
        switch (key) {
            case 'keyValues':
                if (Boolean(Boolean(oldVal) ^ Boolean(newVal))) return newVal
                return Array.from(new Set([
                    ...Array.from(oldVal.keys() ?? []),
                    ...Array.from(newVal.keys() ?? [])
                ])).every(k => oldVal.get(k) === newVal.get(k)) ? undefined : newVal
            case 'image':
                const image = {}
                if (oldVal?.alt !== newVal?.alt) image.alt = newVal?.alt ?? null
                if (oldVal?.src !== newVal?.src) image.src = newVal?.src ?? null
                return (image.alt === undefined && image.src === undefined)
                    ? undefined
                    : (image.alt || image.src)
                        ? image
                        : null
            default: return newVal
        }
    }

    const handleUpdate = async form => {
        const updatedDataItem = { id: entity.id, ...getDataItemFromForm(form) }
        const updates = new Map()
        for (const key of Object.keys(updatedDataItem)) {
            const updatedValue = getUpdatedValue(key, entity[key] || null, updatedDataItem[key] || null)
            if (updatedValue === undefined) continue
            updates.set(key, updatedValue)
        }

        if (!updates.size) return

        await updateEntity(updatedDataItem)
        onSubmit()
    }

    const handleCreate = form => { onEntityChange({ ...entity ?? {}, ...getDataItemFromForm(form) }) }

    const handleSubmit = e => {
        e.preventDefault()
        if (!e.target.reportValidity()) return

        const upsertFunc = entity?.id ? handleUpdate : handleCreate
        upsertFunc(e.target)
    }

    const onChangeCb = (cb, ...m) => e => {
        e.target.setCustomValidity('')
        cb(m.reduce((acc, c) => acc[c]?.(), e.target.value))
    }

    return (
        <form ref={ref} onSubmit={handleSubmit}>
            <HoverableTextField
                name="handle"
                required={!!entity?.groups}
                label="Handle"
                value={handle}
                onChange={onChangeCb(setHandle, 'trim', 'toLowerCase')}
                variant="outlined"
                fullWidth
                type="text"
                margin="dense"
                placeholder="unique-handle" />
            <HoverableTextField
                name="tags"
                label="Tags"
                value={tags}
                onChange={onChangeCb(setTags, 'toLowerCase')}
                variant="outlined"
                fullWidth
                type="text"
                margin="dense"
                onBlur={() => setTags(tags.split(',').map(e => e.trim()).join())}
                placeholder="tag1,tag2,..." />
            <ImageFields
                alt={image.alt}
                onAltChange={alt => setImage({ ...image, alt })}
                src={image.src}
                onSrcChange={src => setImage({ ...image, src })} />
            <KeyValueFields
                value={keyValues}
                onChange={setKeyValues} />
            {entity?.id && sharedHandles?.length > 1 && (
                <>
                    <hr style={{ border: "1px solid #007ec8", marginTop: '15px', marginBottom: '15px' }} />
                    <label>Shared With:</label>
                    <ul style={{ listStyleType: "none", lineHeight: '40px' }}>
                        {sharedHandles.map((e, i) => (
                            <li key={i} style={{ display: 'inline' }}>
                                <Tooltip arrow title={e}>
                                    <Chip onClick={() => onLinkClick(e)} variant="outlined" label={e.split("/").slice(-2).join("/")} className={e === window.location.pathname ? 'current-chip' : ''} />
                                </Tooltip>
                            </li>
                        ))}
                    </ul>
                </>
            )}
        </form>
    )
})

export default EntityForm