import { FormControl, FormGroup, Grid, InputLabel, List, ListItem } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import HoverableTextField from '../HoverableTextField'
import './index.css'

const ImageFields = ({ alt: _alt, onAltChange, src: _src, onSrcChange }) => {
    const [alt, setAlt] = useState(_alt)
    const [src, setSrc] = useState(_src)
    const [shrink, setShrink] = useState(Boolean(_alt || _src))

    useEffect(() => {
        if (alt !== _alt) onAltChange(alt)
    }, [alt, _alt, onAltChange])

    useEffect(() => {
        if (src !== _src) onSrcChange(src)
    }, [src, _src, onSrcChange])

    const handleFocus = () => !shrink && setShrink(true)
    const handleBlur = () => !alt && !src && !!shrink && setShrink(false)

    return (
        <FormControl
            className="image-fields"
            fullWidth
            margin="dense"
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            <InputLabel shrink>Image</InputLabel>
            <FormGroup>
                <List>
                    <ListItem>
                        <Grid container spacing={2} alignItems="flex-end">
                            <Grid item xs={6}>
                                <HoverableTextField
                                    required={Boolean(src)}
                                    name="imageAlt"
                                    variant="outlined"
                                    fullWidth
                                    type="text"
                                    label="Alt"
                                    margin="dense"
                                    value={alt}
                                    onChange={e => setAlt(e.target.value)}
                                    placeholder="Alternate text for the image"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <HoverableTextField
                                    required={Boolean(alt)}
                                    name="imageSrc"
                                    variant="outlined"
                                    fullWidth
                                    type="text"
                                    label="Src"
                                    margin="dense"
                                    value={src}
                                    onChange={e => setSrc(e.target.value.trim())}
                                    placeholder="ie. https://www..."
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            </FormGroup>
        </FormControl>
    )
}

export default ImageFields