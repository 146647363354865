import Tools from "../components/Tools";

import Callback from "../components/Callback";
import Logout from "../components/Logout";
import CMS from "../components/Tools/CMS";

const staticRoutes = [
  { path: "/callback", component: Callback, isPublic: true },
  { path: "/logout", component: Logout, isPublic: true },
  { path: "/websites", component: CMS, isPublic: false },
  { path: "/websites/:handles+", component: CMS, isPublic: false },
  { path: "/(|tools)", component: Tools, isPublic: false },
];

export default staticRoutes;
