import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, ListSubheader, TextField } from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import React, { useState} from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Draggable from '../Draggable'
import GroupCard from '../GroupCard'
import { deleteRelation, updatePositions } from '../../../../../api/api'
import './index.css'
import { useEffect } from 'react'


const EntityDrawer = ({entity, onGroupClick, onCreateNewGroup, onShareClick, onUpdateGroup, onDelete, handleSetGroupData }) => {
    const [newGroupHandle, setNewGroupHandle] = useState(null)
    const [entityGroups, setEntityGroups] = useState(entity.groups)

    useEffect(() => {
        setEntityGroups(entity.groups)
    }, [entity.groups])

    const handleDelete = async (group) => {
        await deleteRelation(entity.id, group.id)
        onDelete(group)
    }

   const moveGroup = (dragIndex, hoverIndex) => {
        const tmp_groups = [...entityGroups]
        tmp_groups.splice(dragIndex, 1)
        tmp_groups.splice(hoverIndex, 0, entityGroups[dragIndex])
        setEntityGroups(tmp_groups)
   }

   const dropGroup = (group) => {
        const oldPos = entity.groups.findIndex(e => e.id === group.id) + 1
        const newPos = entityGroups.findIndex(e => e.id === group.id) + 1
        if(oldPos !== newPos){
            handleSetGroupData(entityGroups)
            updatePositions(entity.id, group.id, 'entity', oldPos ,newPos)
        }
    }

    return (
        <Drawer className="entity-drawer" anchor="left" variant="permanent" color="default">
            <DndProvider backend={HTML5Backend}>
                <List >
                    <Divider />
                    {entityGroups?.map((g, i) => (
                        <Draggable
                            key={g.id}
                            id={g.id}
                            index={i}
                            onMove={moveGroup}
                            onDrop={() => dropGroup(g)}
                            Component={GroupCard}
                            componentProps={{
                                group: g,
                                onClick: onGroupClick,
                                onShareClick: onShareClick,
                                onUpdateGroup: onUpdateGroup,
                                onDelete: handleDelete
                            }}
                        />
                    ))}
                    <ListSubheader />
                    {newGroupHandle === null ? (
                        <ListItem className="new-group-btn" onClick={() => setNewGroupHandle('')}>
                            <ListItemIcon>
                                <Add fontSize="small" />
                            </ListItemIcon>
                            <ListItemText secondary="New Group" />
                        </ListItem>
                    ) : (
                        <ListItem className="new-group">
                            <ListItemIcon>
                                <Edit />
                            </ListItemIcon>
                            <TextField
                                placeholder="group-handle"
                                autoFocus
                                required
                                value={newGroupHandle}
                                onChange={e => (!e.target.value || e.target.value.trim()) && (e.target.value.slice(-2) !== '- ') && setNewGroupHandle(e.target.value.replace(/ /g, '-').trim().toLowerCase())}
                                onKeyUp={async e => {
                                    if (e.key !== 'Enter') return;
                                    await onCreateNewGroup(entity.id, e.target.value)
                                    setNewGroupHandle(null)}
                                }
                                onBlur={e => !e.target.value && setNewGroupHandle(null)}
                            />
                        </ListItem>
                    )}
                </List>
            </DndProvider>
        </Drawer>
    )
}

export default EntityDrawer