import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import App from './App'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import ErrorBoundary from './components/ErrorBoundary'
import { ToastProvider } from './components/ToastProvider'
import WithAxios from './components/WithAxios'
import './index.css'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

ReactDOM.render(
    <ErrorBoundary>
        <ToastProvider>
            <WithAxios>
                <MuiThemeProvider theme={createMuiTheme({ typography: { useNextVariants: true } })}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </MuiThemeProvider>
            </WithAxios>
        </ToastProvider>
    </ErrorBoundary>,
    document.getElementById('root')
)
