import React, { useState } from 'react';
import { Modal, Box } from "@material-ui/core";
import { herokuDeploySiteById } from '../../api/api';
import { useHistory } from 'react-router-dom'

const modalStyles = {
    herokuModelStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 250,
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    confirmationModalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        height: 140,
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    }
};

export default function HerokuDeployModal({ statuses, onClose, selectedEnvironment }) {
    const [confirmationModal, setConfirmationModal] = useState(false)
    const history = useHistory()
    let isPending = true
    if (statuses) isPending = statuses[0]?.status.includes("pending")
    const manuallyDeploySite = async () => {
        const activeWebsite = history.location.pathname.split("/")[2] + (selectedEnvironment === "staging" ? ("-" + selectedEnvironment) : "")
        await herokuDeploySiteById({
            'weboost-canada': process.env.REACT_APP_WEBOOST_CANADA_HEROKU_ID,
            'weboost': process.env.REACT_APP_WEBOOST_HEROKU_ID,
            'wilsonpro': process.env.REACT_APP_WILSONPRO_HEROKU_ID,
            'wilsonpro-canada': process.env.REACT_APP_WILSONPRO_CANADA_HEROKU_ID,
            'wilsonpro-staging': process.env.REACT_APP_WILSONPRO_STAGING_HEROKU_ID,
            'weboost-staging': process.env.REACT_APP_WEBOOST_STAGING_HEROKU_ID
        }[activeWebsite])
        onClose()
    }

    return (
        <div>
            <Modal
                open={true}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    style={modalStyles.herokuModelStyle}
                >
                    <div style={{ fontSize: "25px", textDecoration: "underline", textAlign: "center", paddingBottom: ".5rem" }} id="modal-modal-title" variant="h6" component="h2"><strong>
                        Previous Heroku Re-Deploy Information
                    </strong>
                    </div>
                    <table
                        style={{ width: "100%", marginLeft: "1rem" }}
                    >
                        <tbody>
                            <tr>
                                <th>Build Status</th>
                                <th>Build Started</th>
                                <th>Build Finished</th>
                            </tr>
                            {statuses?.map((s, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{s.status}</td>
                                        <td>{new Date(s.created_at).toLocaleString()}</td>
                                        <td>{new Date(s.updated_at).toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div style={{ width: "100%", textAlign: "center", paddingTop: ".75rem" }} >
                        <button disabled={isPending} style={{ marginRight: ".50rem", width: "10rem", borderRadius: ".5rem", cursor: "pointer" }} onClick={selectedEnvironment === 'production' ? (() => setConfirmationModal(true)) : (manuallyDeploySite)}>Re-Deploy</button>
                        <button style={{ width: "10rem", borderRadius: ".5rem" }} onClick={onClose}>Cancel</button>
                    </div>
                </Box >
            </Modal >
            {
                confirmationModal &&
                <Modal
                    open={true}
                    onClose={onClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        style={modalStyles.confirmationModalStyle}
                    >
                        <div style={{ fontSize: "25px", textTransform: "uppercase", textAlign: "center", paddingBottom: ".5rem" }} id="modal-modal-title" variant="h6" component="h2">
                            <strong>
                                Are you sure you would like to re-deploy production?
                            </strong>
                        </div>
                        <div style={{ width: "100%", textAlign: "center", paddingTop: ".75rem" }} >
                            <button disabled={isPending} style={{ marginRight: ".50rem", width: "10rem", borderRadius: ".5rem", cursor: "pointer" }} onClick={manuallyDeploySite}>Confirm</button>
                            <button style={{ width: "10rem", borderRadius: ".5rem" }} onClick={() => setConfirmationModal(false)}>Cancel</button>
                        </div>
                    </Box>
                </Modal >
            }
        </div >
    );
}